export class ToolBarChip {
    id: string;
    icon: string;
    text: string;
    color = 'primary';
    callback: Function = null;

    constructor(data: Partial<ToolBarChip>) {
        Object.assign(this, data);
    }

    click() {
        if (this.callback) {
            this.callback(this);
        }
    }
}
